import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  @font-face {
    font-family: 'TrajanPro-Regular';
    src: url('/fonts/TrajanPro-Regular.ttf');
    src: url('/fonts/TrajanPro-Regular.ttf') format('truetype');
  }
  @font-face {
    font-family: 'ZapfinoExtra-LTPro';
    src: url('/fonts/ZapfinoExtraLTPro.otf');
    src: url('/fonts/ZapfinoExtraLTPro.otf') format('truetype');
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
  }

  html {
    font-size: 10px;
    font-family: 'Inter-Regular';
  }

  body {
    background: #fff;
    
    &.modal-open {
      overflow: hidden;
    }

    .hidden {
      display:none;
    }
  }
  a {
    text-decoration: none;
  }
`;
