import React from "react";
import "./App.css";
import Home from "../src/pages/home";
import GlobalStyles from "../src/styles/global-styles";

function App() {
  return (
    <React.Fragment>
      <GlobalStyles />
      <Home />
    </React.Fragment>
  );
}

export default App;
