import React from "react";
import styled from "styled-components";

const Wrapper = styled.header`
  padding: 10px;
`;

const Title = styled.h1`
  font-family: ZapfinoExtra-LTPro;
  color: #500028;
  font-size: 62px;
  text-align: center;
  margin-bottom: 10px;

  @media (max-width: 320px) {
    font-size: 48px;
  }
`;

const Subtitle = styled.h2`
  font-family: TrajanPro-Regular;
  color: #500028;
  font-size: 18px;
  text-align: center;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

const Description = styled.p`
  font-family: TrajanPro-Regular;
  color: #500028;
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
`;

export default function Header({ props }) {
  return (
    <Wrapper>
      <Title>Juanan y Ana</Title>
      <Subtitle>25 de julio de 2020</Subtitle>
      <Description>
        Muchas gracias por querer compartir este día con nosotros.
      </Description>
    </Wrapper>
  );
}
