import React from "react";
import styled from "styled-components";
import Header from "../components/header";

const BackgroundLayout = styled.div`
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

const BackgroundImage = styled.div`
  background-image: url("/images/header_small.jpg");
  position: absolute;
  top: 180px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  background-size: 100vw;
  background-repeat: no-repeat;

  @media (min-width: 768px) {
    background-image: url("/images/header.jpg");
  }
`;

const Layout = styled.div`
  margin: 0 240px;

  @media (max-width: 1024px) {
    margin: 0 120px;
  }

  @media (max-width: 768px) {
    margin: 0 40px;
  }

  @media (max-width: 425px) {
    margin: 0 20px;
  }

  @media (max-width: 320px) {
    margin: 0 12px;
  }
`;

const Container = styled.div`
  margin-top: calc(100vh - 186px);

  @media (max-width: 425px) {
    margin-top: calc(100vh - 192px);
  }
`;

const Section = styled.section`
  font-family: TrajanPro-Regular;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  text-align: center;
  margin-bottom: 40px;
  padding: 30px;
  line-height: 1.8;
  position: relative;

  @media (max-width: 425px) {
    padding: 10px;
  }
`;

const SectionTitle = styled.h1`
  font-family: ZapfinoExtra-LTPro;
  color: white;
  font-size: 56px;
  text-align: left;
  margin-bottom: 10px;

  @media (max-width: 425px) {
    font-size: 40px;
  }
`;

const Block = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 30px;
`;

const BlockTitle = styled.h2`
  font-size: 1.1em;
  line-height: 120%;
  padding: 0 0px;
  margin-bottom: 10px;

  @media (max-width: 425px) {
    text-align: center;
  }
`;

const AnaImage = styled.img.attrs({
  src: "/images/ana-thumb.jpg",
})`
  width: 200px;
  background-color: black;
  margin-right: 20px;
  margin-bottom: 0;
  float: left;
  border: 4px solid #090909;

  @media (max-width: 425px) {
    float: none;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
`;

const JuananImage = styled.img.attrs({
  src: "/images/juanan-thumb.jpg",
})`
  width: 200px;
  background-color: black;
  margin-right: 20px;
  margin-bottom: 0;
  float: left;
  border: 4px solid #090909;

  @media (max-width: 425px) {
    float: none;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
`;

const Manuelbe = styled.img.attrs({
  src: "/images/manuelbe.jpg",
})`
  width: 300px;
  background-color: black;
  margin-left: 20px;
  margin-bottom: 0;
  float: right;
  border: 4px solid #090909;

  @media (max-width: 425px) {
    width: 100%;
    float: none;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
`;

const PirineosImage = styled.img.attrs({
  src: "/images/pirineos.jpg",
})`
  width: 400px;
  background-color: black;
  margin-right: 20px;
  margin-bottom: 0;
  float: left;
  border: 4px solid #090909;

  @media (max-width: 425px) {
    width: 100%;
    float: none;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
`;

const TextBlock = styled.div`
  margin-bottom: 15px;

  @media (max-width: 425px) {
    text-align: center;
  }
`;

const BankAccount = styled.div`
  font-size: 1.8em;
  line-height: 150%;
  padding: 15px 15px;
  text-align: center;
  margin-bottom: 15px;

  @media (max-width: 425px) {
    font-size: 1.4em;
  }
`;

const JuananAnaImage = styled.img.attrs({
  src: "/images/juanan-y-ana.jpg",
})`
  width: 350px;
  margin-right: 20px;
  margin-bottom: 0;
  border: 4px solid #090909;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  display: block;
  margin-bottom: 40px;

  @media (max-width: 425px) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    display: block;
  }
`;

const FinalMessage = styled.h2`
  font-family: ZapfinoExtra-LTPro;
  color: white;
  font-size: 56px;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;

  @media (max-width: 425px) {
    font-size: 40px;
  }
`;

export default function Home({ props }) {
  return (
    <React.Fragment>
      <BackgroundLayout>
        <BackgroundImage />
      </BackgroundLayout>
      <Layout>
        <Header />
        <Container>
          <Section>
            <SectionTitle>La novia y el novio</SectionTitle>
            <Block>
              <BlockTitle>ANA</BlockTitle>
              <AnaImage />
              <TextBlock>
                De padre salmantino y madre burgalesa, es la pequeña de nueve
                hermanos. ¡Sí, nueve! Nació en Guayaquil (Ecuador) cuando sus
                padres se encontraban allí como misioneros católicos.
              </TextBlock>
              <TextBlock>
                De pequeña la encantaba jugar al futbol en el parque con sus
                hermanos y montar en bicicleta. Estudió óptica y después de unos
                años trabajando en Extremadura regresó a Madrid para vivir de
                nuevo con sus padres.
              </TextBlock>
              <TextBlock>
                Le apasiona la música; estuvo 7 años en el conservatorio y toca
                la viola y la guitarra.
              </TextBlock>
            </Block>
            <Block>
              <BlockTitle>JUANAN</BlockTitle>
              <JuananImage />
              <TextBlock>
                De padre navarro con raices sorianas y madre vallisoletana, es
                el pequeño de seis hermanos varones. ¡Sí, seis chicos! Nació en
                Madrid aunque la familia siempre estuvo a caballo entre la
                capital y Valladolid.
              </TextBlock>
              <TextBlock>
                Lo que más recuerda de su infancia son las Navidades en familia.
                Ingeniero industrial de profesión, después de muchos años
                trabajando encontró su vocación en la informática.
              </TextBlock>
              <TextBlock>
                El amor a la montaña le llevó a comenzar otro camino,
                completamente inespereado.
              </TextBlock>
            </Block>
            <SectionTitle>Cómo nos conocimos</SectionTitle>
            <Block>
              <Manuelbe />
              <TextBlock>
                No tenemos un recuerdo claro de cuándo ni dónde nos conocimos
                pero fue aproximadamente hace unos 10 años.
              </TextBlock>
              <TextBlock>
                Lo que sí podemos decir es que el hecho de compartir muchas
                cosas, en especial la fe, en torno a un grupo de amigos, fue los
                que nos mantuvo de algún modo siempre en contacto durante todos
                esos años.
              </TextBlock>
            </Block>
            <SectionTitle>Cómo empezó todo</SectionTitle>
            <Block>
              <PirineosImage />
              <TextBlock>
                Empezó sin que ninguno de los dos se diese cuenta el día de
                Santiago Apostol del año 2018.
              </TextBlock>
              <TextBlock>
                Días después, nos fuimos con un grupo de amigos a los
                Pirinieos... y a Lourdes... y a Navarra... y a San Juan de
                Luz... y a Javier...
              </TextBlock>
              <TextBlock>
                Y cuando llegamos de nuevo a Madrid, Ana se olvidó
                misteriosamente en el coche de Juanan un trozo de queso que
                había comprado.
              </TextBlock>
              <TextBlock>
                Y claro, tuvimos que quedar al día siguiente.
              </TextBlock>
            </Block>
            <SectionTitle>Tiempos de virus</SectionTitle>
            <Block>
              <TextBlock>
                Ha sido un tiempo difícil, lleno de incertidumbres y de miedos.
                Pero también ha sido un timepo de gracia el hecho de poder
                vivirlo juntos. Separados físicamente, pero unidos.
              </TextBlock>
              <TextBlock>
                Quereremos darle gracias a Dios por haber cuidado de nuestras
                familias, por habernos concedido el poder cuidar de nuestros
                padres, por regalarnos una comunidad con la que vivir la fe y
                por tantas y tantas cosas.
              </TextBlock>
            </Block>
          </Section>
          <Section>
            <SectionTitle>Gracias a ti también</SectionTitle>
            <Block>
              <TextBlock>
                Si has leido hasta aquí debe ser que nos quieres mucho.
              </TextBlock>
              <TextBlock>
                Deseamos poner fin a un tiempo difícil con un reencuentro. Y
                empezar una nueva historia juntos rodeados de todos vosotros.
              </TextBlock>
              <TextBlock>Si nos quieres ayudar a empezarla:</TextBlock>
              <BankAccount>ES84 0239 0806 7200 1227 3025</BankAccount>
              <TextBlock>
                Pero recuerda que tu sola presencia es ya un motivo de inmensa
                alegría para nosotros.
              </TextBlock>
              <JuananAnaImage />
              <FinalMessage>¡Nos vemos el 25 de julio!</FinalMessage>
              <FinalMessage>Juanan y Ana</FinalMessage>
            </Block>
          </Section>
        </Container>
      </Layout>
    </React.Fragment>
  );
}
